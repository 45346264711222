import FiberGlass1 from "../assets/images/fiberglass-polyester-braided-cable-1.png";
import FiberGlass2 from "../assets/images/fiberglass-polyester-braided-cable-2.jpg";
import silicon_rubber_cables from "../assets/images/silicon_rubber_cables.jpg";
import silicon_rubber_cables1 from "../assets/images/silicon_rubber_cables1.jpg";
import enameled_aluminum_wire from "../assets/images/enameled_aluminum_wire.jpg";
import enameled_aluminum_wire1 from "../assets/images/enameled_aluminum_wire1.jpg";
import enameled_copper_wire from "../assets/images/enameled_copper_wire.jpg";
import enameled_copper_wire1 from "../assets/images/enameled_copper_wire1.jpg";
import fiberglass_epoxy_sheet_FR4_G10_G11 from "../assets/images/fiberglass_epoxy_sheetFR4_G10_G11.jpeg";
import fiberglass_epoxy_sheet_FR4_G10_G11_1 from "../assets/images/fiberglass_epoxy_sheetFR4_G10_G11_1.jpeg";
import glass_epoxy_sheet_3240 from "../assets/images/glass_epoxy_sheet_3240.jpg";
import glass_epoxy_sheet_3240_1 from "../assets/images/glass_epoxy_sheet_3240_1.jpeg";
import glass_epoxy_sheet_3240_data from "../assets/images/glass_epoxy_sheet_3240_data.png";
import press_board from "../assets/images/press_board.jpg";
import press_board1 from "../assets/images/press_board1.jpg";
import press_board_data from "../assets/images/press_board_data.png";
import hylam_sheet_bakelite_sheet from "../assets/images/hylam_sheet_bakelite_sheet1.jpg";
import hylam_sheet_bakelite_sheet1 from "../assets/images/hylam_sheet_bakelite_sheet.jpg";
import smc_sheet from "../assets/images/smc_sheet.jpg";
import smc_sheet1 from "../assets/images/smc_sheet1.jpg";
import fiberglass_cloths from "../assets/images/fiberglass_cloths.jpg";
import fiberglass_cloths1 from "../assets/images/fiberglass_cloths1.jpg";
import fiberglass_cloths_data from "../assets/images/fiberglass_cloths_data.png";
import fiberglass_epoxy_wedges from "../assets/images/fiberglass_epoxy_wedges.jpeg";
import fiberglass_epoxy_wedges1 from "../assets/images/fiberglass_epoxy_wedges1.jpeg";
import polyurethane_fiberglass_sleeving from "../assets/images/polyurethane_fiberglass_sleeving_.png";
import polyurethane_fiberglass_sleeving1 from "../assets/images/polyurethane_fiberglass_sleeving_1.jpg";
import polyurethane_fiberglass_sleeving_data from "../assets/images/polyurethane_fiberglass_sleeving_data.png";
import fire_resistant_pyro_sleeve from "../assets/images/fire_resistant_pyro_sleeve.jpeg";
import fire_resistant_pyro_sleeve1 from "../assets/images/fire_resistant_pyro_sleeve1.jpeg";
import silicone_coated_fiberglass_sleeve from "../assets/images/silicone_coated_fiberglass_sleeve.jpeg";
import silicone_coated_fiberglass_sleeve1 from "../assets/images/silicone_coated_fiberglass_sleeve1.jpg";
import silicone_coated_fiberglass_sleeve_data from "../assets/images/silicone_coated_fiberglass_sleeve_data.png";
import acrylic_fiberglass_sleeving from "../assets/images/acrylic_fiberglass_sleeving.jpg";
import acrylic_fiberglass_sleeving1 from "../assets/images/acrylic_fiberglass_sleeving1.png";
import acrylic_fiberglass_sleeving_data from "../assets/images/acrylic_fiberglass_sleeving_data.png";
import unvarnished_fiberglass_sleeve from "../assets/images/unvarnished_fiberglass_sleeve.jpg";
import unvarnished_fiberglass_sleeve1 from "../assets/images/unvarnished_fiberglass_sleeve1.jpg";
import varnished_fiberglass_sleeve_f_class from "../assets/images/varnished_fiberglass_sleeve_f_class.png";
import varnished_fiberglass_sleeve_f_class1 from "../assets/images/varnished_fiberglass_sleeve_f_class1.png";
import srbp_tubes from "../assets/images/srbp_tubes.png";
import srbp_tubes1 from "../assets/images/srbp_tubes1.png";
import cotton_insulation_tapes from "../assets/images/cotton_insulation_tapes.png";
import cotton_insulation_tapes1 from "../assets/images/cotton_insulation_tapes1.png";
import fiberglass_tapes from "../assets/images/fiberglass_tapes.jpeg";
import fiberglass_tapes1 from "../assets/images/fiberglass_tapes1.jpg";
import polymide_tape from "../assets/images/polymide_tape.jpg";
import polymide_tape1 from "../assets/images/polymide_tape1.jpg";
import pgmp_tapes from "../assets/images/pgmp_tapes.jpg";
import pgmp_tapes1 from "../assets/images/pgmp_tapes1.png";
import dupont_nomex_laminated_paper from "../assets/images/dupont_nomex_laminated_paper.png";
import dupont_nomex_laminated_paper1 from "../assets/images/dupont_nomex_laminated_paper1.jpg";
import laminated_fleece_dmd from "../assets/images/laminated_fleece_dmd.png";
import laminated_fleece_dmd1 from "../assets/images/laminated_fleece_dmd1.png";
import laminated_fleece_dmd_data from "../assets/images/laminated_fleece_dmd_data.png";
import saturated_fleece_paper from "../assets/images/saturated_fleece_paper.png";
import saturated_fleece_paper_1 from "../assets/images/saturated_fleece_paper1.jpg";
import dupont_nomex from "../assets/images/dupont_nomex1.png";
import dupont_nomex1 from "../assets/images/dupont_nomex.png";
import unsaturated_fleece_paper from "../assets/images/unsaturated_fleece_paper.png";
import polyester_film_electrical_insulation from "../assets/images/polyester_film_electrical_insulation.png";
import polyester_film_electrical_insulation1 from "../assets/images/polyester_film_electrical_insulation1.jpg";
import polyester_film_electrical_insulation_data from "../assets/images/polyester_film_electrical_insulation_data.png";
import pure_aramid_paper_ul_approved from "../assets/images/pure_aramid_paper_(ul_approved).jpg";
import pure_aramid_paper_ul_approved1 from "../assets/images/pure_aramid_paper_(ul_approved)1.jpg";
import pure_aramid_paper_ul_approved_data from "../assets/images/pure_aramid_paper_ul_approved_data.png";
import laminated_aramid_paper_apa from "../assets/images/laminated_aramid_paper_apa.png";
import laminated_aramid_paper_apa1 from "../assets/images/laminated_aramid_paper_apa1.png";
import laminated_aramid_paper_apa_data from "../assets/images/laminated_aramid_paper_apa_data.png";
import dr_beck_insulating_varnish from "../assets/images/dr_beck_insulating_varnish.png";
import dr_beck_insulating_varnish1 from "../assets/images/dr_beck_insulating_varnish1.jpg";
import dr_beck_insulating_varnish_data from "../assets/images/dr_beck_insulating_varnish_data.png";
import dr_beck_elmotherm_f_50_varnishes from "../assets/images/dr_beck_elmotherm_f_50_varnishes.jpg";
import dr_beck_elmotherm_f_50_varnishes_data from "../assets/images/dr_beck_elmotherm_f_50_varnishes_data.png";
import becktol_red_grey_varnish from "../assets/images/becktol_red_grey_varnish.png";
import becktol_red_grey_varnish1 from "../assets/images/becktol_red_grey_varnish1.jpg";
import becktol_red_grey_varnish1_data from "../assets/images/becktol_red_grey_varnish_data.png";
import ptfe_wire from "../assets/images/ptfe_wire.png";
import ptfe_wire1 from "../assets/images/ptfe_wire1.jpg";
import teflon_cable from "../assets/images/teflon_cable.png";
import teflon_cable1 from "../assets/images/teflon_cable1.png";
import teflon_cable_data from "../assets/images/teflon_cable_data.png";

export const menuItems = [
  {
    id: "home",
    title: "Home",
    url: "/",
  },
  {
    id: "about",
    title: "About",
    url: "/about",
  },
  {
    id: "services",
    title: "Services",
    url: "/services",
  },
  {
    id: "products",
    title: "Products",
    url: "/products/fiberglass_&_polyester_braided_cable",
    submenu: [
      {
        title: "Fiberglass & Polyester Braided Cable",
        url: "products/fiberglass_&_polyester_braided_cable",
        submenu: [
          {
            title: "Fiberglass & Polyester Braided Cable",
            url: "products/fiberglass_&_polyester_braided_cable",
          },
          { title: "Fiberglass Cables", url: "products/fiberglass_cables" },
          {
            title: "Silicon Rubber Cables",
            url: "products/silicon_rubber_cables",
          },
        ],
      },
      {
        title: "Enameled Winding Wire",
        url: "products/enameled_aluminum_wire",
        submenu: [
          {
            title: "Enameled Aluminum Wire",
            url: "products/enameled_aluminum_wire",
          },
          {
            title: "Enameled Copper Wire",
            url: "products/enameled_copper_wire",
          },
        ],
      },
      {
        title: "Electrical Insulation Sheets",
        url: "products/fiberglass_epoxy_sheet:FR4_G10_G11",
        submenu: [
          {
            title: "Fiberglass Epoxy Sheet: FR4 / G10 / G11",
            url: "products/fiberglass_epoxy_sheet:FR4_G10_G11",
          },
          {
            title: "Glass Epoxy Sheet 3240",
            url: "products/glass_epoxy_sheet_3240",
          },
          { title: "Press Board", url: "products/press_board" },
          {
            title: "Hylam Sheet / Bakelite Sheet",
            url: "products/hylam_sheet_bakelite_sheet",
          },
          { title: "SMC Sheet", url: "products/smc_sheet" },
          { title: "Fiberglass cloths", url: "products/fiberglass_cloths" },
          {
            title: "Fiberglass Epoxy Wedges",
            url: "products/fiberglass_epoxy_wedges",
          },
        ],
      },
      {
        title: "Electrical Insulating sleeves and tubes",
        url: "products/polyurethane_fiberglass_sleeving_(pu)",
        submenu: [
          {
            title: "Polyurethane Fiberglass Sleeving (PU)",
            url: "products/polyurethane_fiberglass_sleeving_(pu)",
          },
          {
            title: "Fire Resistant / Pyro Sleeve",
            url: "products/fire_resistant_pyro_sleeve",
          },
          {
            title: "Silicone Coated Fiberglass Sleeve",
            url: "products/silicone_coated_fiberglass_sleeve",
          },
          {
            title: "Acrylic Fiberglass Sleeving",
            url: "products/acrylic_fiberglass_sleeving",
          },
          {
            title: "Unvarnished Fiberglass Sleeve",
            url: "products/unvarnished_fiberglass_sleeve",
          },
          {
            title: "Varnished Fiberglass Sleeve F Class",
            url: "products/varnished_fiberglass_sleeve_f_class",
          },
          { title: "SRBP Tubes", url: "products/srbp_tubes" },
        ],
      },
      {
        title: "Electrical Insulation Tapes",
        url: "products/cotton_insulation_tapes",
        submenu: [
          {
            title: "Cotton Insulation Tapes",
            url: "products/cotton_insulation_tapes",
          },
          { title: "Fiberglass Tapes", url: "products/fiberglass_tapes" },
          { title: "Polymide Tape", url: "products/polymide_tape" },
          { title: "PGMP Tapes", url: "products/pgmp_tapes" },
        ],
      },
      {
        title: "Electical Insulation Paper",
        url: "products/dupont_nomex_laminated_paper",
        submenu: [
          {
            title: "DuPont Nomex Laminated Paper",
            url: "products/dupont_nomex_laminated_paper",
          },
          {
            title: "Laminated Fleece / DMD",
            url: "products/laminated_fleece_dmd",
          },
          {
            title: "Saturated Fleece Paper",
            url: "products/saturated_fleece_paper",
          },
          { title: "DuPont Nomex", url: "products/dupont_nomex" },
          {
            title: "Unsaturated Fleece Paper",
            url: "products/unsaturated_fleece_paper",
          },
          {
            title: "Polyester Film - Electrical Insulation",
            url: "products/polyester_film_electrical_insulation",
          },
        ],
      },
      {
        title: "Aramid paper",
        url: "products/pure_aramid_paper_(ul_approved)",
        submenu: [
          {
            title: "Pure Aramid Paper (UL approved)",
            url: "products/pure_aramid_paper_(ul_approved)",
          },
          {
            title: "Laminated Aramid Paper APA",
            url: "products/laminated_aramid_paper_apa",
          },
        ],
      },
      {
        title: "Insulating Varnishes",
        url: "products/dr_beck_insulating_varnish",
        submenu: [
          {
            title: "Dr Beck Insulating Varnish",
            url: "products/dr_beck_insulating_varnish",
          },
          {
            title: "Dr. Beck Elmotherm F 50 Varnishes",
            url: "products/dr_beck_elmotherm_f_50_varnishes",
          },
          {
            title: "Becktol Red / Grey Varnish",
            url: "products/becktol_red_grey_varnish",
          },
        ],
      },
      {
        title: "PTFE Wire and Sleeve",
        url: "products/ptfe_wire",
        submenu: [
          { title: "PTFE Wire", url: "products/ptfe_wire" },
          { title: "Teflon Cable", url: "products/teflon_cable" },
        ],
      },
    ],
  },
  {
    id: "certificates",
    title: "Certification",
    url: "/certification",
  },
  {
    id: "contact",
    title: "Contact",
    url: "/contact",
  },
];

export const subDetailsMenu = [
  {
    title: "Fiberglass & Polyester Braided Cable",
    url: "fiberglass_&_polyester_braided_cable",
    submenu: [
      {
        title: "Fiberglass & Polyester Braided Cable",
        url: "fiberglass_&_polyester_braided_cable",
      },
      { title: "Fiberglass Cables", url: "fiberglass_cables" },
      { title: "Silicon Rubber Cables", url: "silicon_rubber_cables" },
    ],
  },
  {
    title: "Enameled Winding Wire",
    url: "enameled_aluminum_wire",
    submenu: [
      { title: "Enameled Aluminum Wire", url: "enameled_aluminum_wire" },
      { title: "Enameled Copper Wire", url: "enameled_copper_wire" },
    ],
  },
  {
    title: "Electrical Insulation Sheets",
    url: "fiberglass_epoxy_sheet:FR4_G10_G11",
    submenu: [
      {
        title: "Fiberglass Epoxy Sheet: FR4 / G10 / G11",
        url: "fiberglass_epoxy_sheet:FR4_G10_G11",
      },
      { title: "Glass Epoxy Sheet 3240", url: "glass_epoxy_sheet_3240" },
      { title: "Press Board", url: "press_board" },
      {
        title: "Hylam Sheet / Bakelite Sheet",
        url: "hylam_sheet_bakelite_sheet",
      },
      { title: "SMC Sheet", url: "smc_sheet" },
      { title: "Fiberglass cloths", url: "fiberglass_cloths" },
      { title: "Fiberglass Epoxy Wedges", url: "fiberglass_epoxy_wedges" },
    ],
  },
  {
    title: "Electrical Insulating sleeves and tubes",
    url: "polyurethane_fiberglass_sleeving_(pu)",
    submenu: [
      {
        title: "Polyurethane Fiberglass Sleeving (PU)",
        url: "polyurethane_fiberglass_sleeving_(pu)",
      },
      {
        title: "Fire Resistant / Pyro Sleeve",
        url: "fire_resistant_pyro_sleeve",
      },
      {
        title: "Silicone Coated Fiberglass Sleeve",
        url: "silicone_coated_fiberglass_sleeve",
      },
      {
        title: "Acrylic Fiberglass Sleeving",
        url: "acrylic_fiberglass_sleeving",
      },
      {
        title: "Unvarnished Fiberglass Sleeve",
        url: "unvarnished_fiberglass_sleeve",
      },
      {
        title: "Varnished Fiberglass Sleeve F Class",
        url: "varnished_fiberglass_sleeve_f_class",
      },
      { title: "SRBP Tubes", url: "srbp_tubes" },
    ],
  },
  {
    title: "Electrical Insulation Tapes",
    url: "cotton_insulation_tapes",
    submenu: [
      { title: "Cotton Insulation Tapes", url: "cotton_insulation_tapes" },
      { title: "Fiberglass Tapes", url: "fiberglass_tapes" },
      { title: "Polymide Tape", url: "polymide_tape" },
      { title: "PGMP Tapes", url: "pgmp_tapes" },
    ],
  },
  {
    title: "Electical Insulation Paper",
    url: "dupont_nomex_laminated_paper",
    submenu: [
      {
        title: "DuPont Nomex Laminated Paper",
        url: "dupont_nomex_laminated_paper",
      },
      { title: "Laminated Fleece / DMD", url: "laminated_fleece_dmd" },
      { title: "Saturated Fleece Paper", url: "saturated_fleece_paper" },
      { title: "DuPont Nomex", url: "dupont_nomex" },
      { title: "Unsaturated Fleece Paper", url: "unsaturated_fleece_paper" },
      {
        title: "Polyester Film - Electrical Insulation",
        url: "polyester_film_electrical_insulation",
      },
    ],
  },
  {
    title: "Aramid paper",
    url: "pure_aramid_paper_(ul_approved)",
    submenu: [
      {
        title: "Pure Aramid Paper (UL approved)",
        url: "pure_aramid_paper_(ul_approved)",
      },
      {
        title: "Laminated Aramid Paper APA",
        url: "laminated_aramid_paper_apa",
      },
    ],
  },
  {
    title: "Insulating Varnishes",
    url: "dr_beck_insulating_varnish",
    submenu: [
      {
        title: "Dr Beck Insulating Varnish",
        url: "dr_beck_insulating_varnish",
      },
      {
        title: "Dr. Beck Elmotherm F 50 Varnishes",
        url: "dr_beck_elmotherm_f_50_varnishes",
      },
      { title: "Becktol Red / Grey Varnish", url: "becktol_red_grey_varnish" },
    ],
  },
  {
    title: "PTFE Wire and Sleeve",
    url: "ptfe_wire",
    submenu: [
      { title: "PTFE Wire", url: "ptfe_wire" },
      { title: "Teflon Cable", url: "teflon_cable" },
    ],
  },
];

export const submenuDetails = [
  {
    id: "fiberglass_&_polyester_braided_cable",
    title: "Fiberglass & Polyester Braided Cable",
    images: [FiberGlass1, FiberGlass2],
    description: {
      brand: "FPC CABLES",
      minimum_Order_quantity: "100 Meter",
      color: "Red,Black,Blue,Yellow,Orange,Brown etc",
      material: "Fiberglass Braided Copper Wire",
      length: "100 Meters",
      conductor_type: "Bare copper / Tinned copper",
      packaging_type: "Blue reel / Spool",
      surface_treatment: "Braided",
      temperature_range: "155-180",
      voltage: "600/1100",
      power: "6 KV",
      diameter: "0.50 sqmm - 240 sqmm",
    },
    features: [
      "Bunched Tinned Copper As per “IS-8130-1976 Class-5” (TPC)",
      "Bunched bare copper (BC)",
      "Bunched Nickle plated copper (NPC)",
      "Bunched Silver Plated Copper (SPC)",
      "Bunched Stainless steel & Bare copper (SSBC): Heater special",
      "Bunched Stainless steel & Tinned copper (SSTC): Heater Special",
      "Layers of DMD Wrapped & braided with Fiberglass Yarn or Polyester yarn",
      "Layers of DMDT Wrapped & braided with Fiberglass Yarn or Polyester yarn",
    ],
    innerSheath: [
      "Mylar (Polyester Tape) Wrapped",
      "Teflon (PTFE) Tape Wrapped",
      "Silicone extruded conductor",
      "Kapton Tape Wrapped",
    ],

    additionalFeature: [
      "Very good Thermal Stability",
      "Very good Dielectric Constant",
      "Good Resistance to Moisture & Chemicals",
      "These cables are highly heated and are made to meet your different requirements.",
      "These cables are having an outstanding and unique combination of electrical, mechanical, thermal and chemical properties.",
    ],
    additionalInnerSheath: [
      "Mylar (Polyester Tape) Wrapped",
      "Teflon (PTFE) Tape Wrapped",
      "Silicone extruded conductor",
      "Kapton Tape Wrapped",
    ],
  },
  {
    id: "fiberglass_cables",
    title: "Fiberglass Cables",
    images: [FiberGlass2, FiberGlass1],
    description: {
      brand: "FIBERGLASS CABLES",
      minimum_Order_quantity: "100 Meter",
      color: "Red,Black,Blue,Yellow,Orange,Brown etc",
      material: "Fiberglass Braided Copper Wire",
      length: "100 Meters",
      conductor_type: "Bare copper / Tinned copper",
      packaging_type: "Blue reel / Spool",
      surface_treatment: "Braided",
      temperature_range: "155-180",
      voltage: "600/1100",
      power: "6 KV",
      diameter: "0.50 sqmm - 240 sqmm",
    },
    features: [
      "Bunched Tinned Copper As per “IS-8130-1976 Class-5” (TPC)",
      "Bunched bare copper (BC)",
      "Bunched Nickle plated copper (NPC)",
      "Bunched Silver Plated Copper (SPC)",
      "Bunched Stainless steel & Bare copper (SSBC): Heater special",
      "Bunched Stainless steel & Tinned copper (SSTC): Heater Special",
      "Layers of DMD Wrapped & braided with Fiberglass Yarn or Polyester yarn",
      "Layers of DMDT Wrapped & braided with Fiberglass Yarn or Polyester yarn",
    ],
    innerSheath: [
      "Mylar (Polyester Tape) Wrapped",
      "Teflon (PTFE) Tape Wrapped",
      "Silicone extruded conductor",
      "Kapton Tape Wrapped",
    ],
    additionalFeature: [
      "Very good Thermal Stability",
      "Very good Dielectric Constant",
      "Good Resistance to Moisture & Chemicals",
      "These cables are highly heated and are made to meet your different requirements.",
      "These cables are having an outstanding and unique combination of electrical, mechanical, thermal and chemical properties.",
    ],
    additionalInnerSheath: [
      "Mylar (Polyester Tape) Wrapped",
      "Teflon (PTFE) Tape Wrapped",
      "Silicone extruded conductor",
      "Kapton Tape Wrapped",
    ],
  },
  {
    id: "silicon_rubber_cables",
    title: "Silicon Rubber Cables",
    images: [silicon_rubber_cables, silicon_rubber_cables1],
    description: {
      brand: "FPC CABLES",
      minimum_Order_quantity: "100 Meter",
      color: "Red,Black,Orange etc",
      material: "Silicone",
      length: "100 Meters",
      conductor_type: "Tinned copper",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "-60 Degree C to + 200 Degree C",
      voltage: "300/500V",
      power: "",
      diameter: "",
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "enameled_aluminum_wire",
    title: "Enameled Aluminum Wire",
    images: [enameled_aluminum_wire, enameled_aluminum_wire1],
    description: {
      brand: "",
      minimum_Order_quantity: "50 Kilogram",
      color: "",
      material: "Copper, Aluminum",
      length: "",
      conductor_type: "Solid",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "",
      voltage: "300/500V",
      power: "",
      diameter: "",
      tensile_strength: "130 Mpa - 150 Mpa",
      insulation_material: "Enameled",
      reference_standards: "NEMA MW1000 -1997 / IEC- 60317 / JIS-3202",
      available_sizes: "SWG 38 (0.15mm) – SWG 10 (3.2mm)",
      insulation_class: "F-155 class, H-180 class, C- 200 Class (Dual Coat)",
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "enameled_copper_wire",
    title: "Enameled Copper Wire",
    images: [enameled_copper_wire, enameled_copper_wire1],
    description: {
      brand: "",
      minimum_Order_quantity: "50 Kilogram",
      color: "",
      material: "Copper, Aluminum",
      length: "",
      conductor_type: "Solid",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "",
      voltage: "300/500V",
      power: "",
      diameter: "",
      tensile_strength: "130 Mpa - 150 Mpa",
      insulation_material: "Enameled",
      reference_standards: "NEMA MW1000 -1997 / IEC- 60317 / JIS-3202",
      available_sizes: "SWG 38 (0.15mm) – SWG 10 (3.2mm)",
      insulation_class: "F-155 class, H-180 class, C- 200 Class (Dual Coat)",
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "fiberglass_epoxy_sheet:FR4_G10_G11",
    title: "Fiberglass Epoxy Sheet: FR4 / G10 / G11",
    images: [
      fiberglass_epoxy_sheet_FR4_G10_G11,
      fiberglass_epoxy_sheet_FR4_G10_G11_1,
    ],
    description: {
      brand: "",
      minimum_Order_quantity: "",
      color: "Light Green, Natural",
      material: "",
      length: "+-25 mm",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "0.15 mm ~ 100 mm",
      insulation_class: "H",
      thickness_variation: "Upto 5 mm +- 0.10 mm, Above 5 mm +- 0.50 mm",
      size: "610 mm X 1020 mm, 1020 mm X 1020 mm, 1030 mm X 1230 mm",
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "glass_epoxy_sheet_3240",
    title: "Glass Epoxy Sheet 3240",
    images: [glass_epoxy_sheet_3240, glass_epoxy_sheet_3240_1],
    description: {
      brand: "",
      minimum_Order_quantity: "100 Kilograms",
      color: "",
      material: "",
      length: "",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "",
      size: "",
      image: [glass_epoxy_sheet_3240_data],
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "press_board",
    title: "Press Board",
    images: [press_board, press_board1],
    description: {
      brand: "",
      minimum_Order_quantity: "50 Kilograms",
      color: "",
      material: "Paper",
      length: "",
      conductor_type: "",
      packaging_type: "Solid",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "8 mm to 50 mm",
      size: "",
      image: [press_board_data],
    },
    features: [
      "Exceptional Electrical Strength",
      "Uniform Thickness",
      "Incompressible",
      "Dimensionally Accurate",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "hylam_sheet_bakelite_sheet",
    title: "Hylam Sheet / Bakelite Sheet",
    images: [hylam_sheet_bakelite_sheet, hylam_sheet_bakelite_sheet1],
    description: {
      brand: "",
      minimum_Order_quantity: "50 Kilograms",
      color: "Brown",
      material: "phenolic resin and fabric paper",
      length: "",
      conductor_type: "",
      packaging_type: "Solid",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "1 mm",
      size: "",
      image: [],
    },
    features: ["Wear Resisting"],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "smc_sheet",
    title: "SMC Sheet",
    images: [smc_sheet, smc_sheet1],
    description: {
      brand: "Sintex",
      minimum_Order_quantity: "50 Kilograms",
      color: "Off-white",
      material: "SMC",
      length: "",
      conductor_type: "",
      packaging_type: "Box",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "1.5 to 15 mm",
      size: "1x1 mts",
      image: [],
    },
    features: [
      "Excellent dielectric properties such as volume resistivity, surface resistivity, arc resistance and comparative tracking index",
      "Can withstand continuous temperatures up to 140°C",
      "Anti-corrosive and resistant to most acids, alkalis and chemicals",
      "Lightweight, strong, stiff, and clean",
      "Non-hygroscopic and retains its dielectric properties even in high humidity",
      "Weatherproof",
      "Self-coloured and",
      "Maintenance-free",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "fiberglass_cloths",
    title: "Fiberglass Cloth",
    images: [fiberglass_cloths, fiberglass_cloths1],
    description: {
      brand: "Sintex",
      minimum_Order_quantity: "50 Kilograms",
      color: "  ",
      material: "Fiberglass",
      length: "",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "13 mm",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "",
      size: '39 "x 5 Yards',
      image: [fiberglass_cloths_data],
    },
    features: [
      "High intensity and quick wet out",
      "Perfect designable character",
      "Anti-corrosion and electric insulation",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "fiberglass_epoxy_wedges",
    title: "Fiberglass Epoxy Wedges",
    images: [fiberglass_epoxy_wedges, fiberglass_epoxy_wedges1],
    description: {
      brand: "",
      minimum_Order_quantity: "50 Kilograms",
      color: "",
      material: "Fiberglass",
      length: "50-300mm",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "210 Degree C",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.2~50mm",
      size: "",
      image: [],
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "polyurethane_fiberglass_sleeving_(pu)",
    title: "Polyurethane Fiberglass Sleeving (PU)",
    images: [
      polyurethane_fiberglass_sleeving,
      polyurethane_fiberglass_sleeving1,
    ],
    description: {
      brand: "",
      minimum_Order_quantity: "",
      color: "yellow, Red, Blue & Green etc",
      material: "Fiberglas, PU",
      length: "50-300mm",
      conductor_type: "",
      packaging_type: "Insulation Sleeving",
      surface_treatment: "",
      temperature_range: "210 Degree C",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.2~50mm",
      size: "",
      heat_resistance: "Bending after heat treatment at 550 Deg. Cel.",
      image: [polyurethane_fiberglass_sleeving_data],
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "fire_resistant_pyro_sleeve",
    title: "Fire Resistant / Pyro Sleeve",
    images: [fire_resistant_pyro_sleeve, fire_resistant_pyro_sleeve1],
    description: {
      brand: "",
      minimum_Order_quantity: "",
      color: "Red",
      material: "Silicone, fiberglass",
      length: "50-300mm",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "310degC",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.2~50mm",
      size: "",
      heat_resistance: "",
      image: [polyurethane_fiberglass_sleeving_data],
    },
    features: [
      "Heat Resistant",
      "Fireproof/ Flame Retardant/ Insulation/water Proof/grease Proof/acid And Alkali Resistant",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "silicone_coated_fiberglass_sleeve",
    title: "Silicone Coated Fiberglass Sleeve",
    images: [
      silicone_coated_fiberglass_sleeve,
      silicone_coated_fiberglass_sleeve1,
    ],
    description: {
      brand: "Smart-Shield",
      minimum_Order_quantity: "50 Meter",
      color: "White, Red, Black, Yellow, Blue, Green etc",
      material: "Fiberglass",
      length: "50-300mm",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "-70 to 260deg C",
      voltage: "1.5 - 7 KV",
      power: "",
      diameter: "1mm-30mm",
      tensile_strength: "",
      insulation_material: "Silicone Coated",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.2~50mm",
      size: "",
      heat_resistance: "",
      image: [silicone_coated_fiberglass_sleeve_data],
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "acrylic_fiberglass_sleeving",
    title: "Acrylic Fiberglass Sleeving",
    images: [acrylic_fiberglass_sleeving, acrylic_fiberglass_sleeving1],
    description: {
      brand: "",
      minimum_Order_quantity: "",
      color: "White, Red, Black, Yellow, Blue, Green etc",
      material: "Fiberglass",
      length: "",
      conductor_type: "Class B",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "7-10 mm",
      size: "",
      heat_resistance: "",
      image: [acrylic_fiberglass_sleeving_data],
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "unvarnished_fiberglass_sleeve",
    title: "Unvarnished Fiberglass Sleeve",
    images: [unvarnished_fiberglass_sleeve, unvarnished_fiberglass_sleeve1],
    description: {
      brand: "Salasar",
      minimum_Order_quantity: "1000 Meter",
      color: "White",
      material: "Fiberglass",
      length: "",
      conductor_type: "",
      packaging_type: "100 mts",
      surface_treatment: "",
      temperature_range: "180 Degree C",
      voltage: "",
      power: "",
      diameter: "1 mm - 30 mm",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.5 - 2 mm",
      size: "0.50 mm to 50 mm",
      heat_resistance: "",
      image: [],
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "varnished_fiberglass_sleeve_f_class",
    title: "Varnished Fiberglass Sleeve F Class",
    images: [
      varnished_fiberglass_sleeve_f_class,
      varnished_fiberglass_sleeve_f_class1,
    ],
    description: {
      brand: "FPC",
      minimum_Order_quantity: "1000 Meter",
      color: "Yellow",
      material: "Fiberglass",
      length: "1 & 100 cm",
      conductor_type: "",
      packaging_type: "100 mts",
      surface_treatment: "",
      temperature_range: "155 Degree C",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.25-0.50 cm",
      size: "",
      heat_resistance: "",
      image: [],
    },
    features: [
      "Thermal property: Oil Resistant F Class Polyurethane Varnished PU Fiberglass Braided Sleevings can endure 170C high temperature for about 3 hours.",
      "Chemical property: Oil Resistant F Class Polyurethane Varnished PU Fiberglass Braided Sleevings has excellent oil resistance and benzene resistance.",
      "Electrical property: 300V rated voltage",
      "Excellent mechanical wearproof and elasticity",
      "CUL, RoHS, REACH certifications",
    ],
    innerSheath: [
      "0.5mm to 4.5mm 200m/roll",
      "5.0mmto 12.0mm 100 m/roll",
      "14.0mm to 35.0mm 50 m/roll;(can be 1 m/segment upon request. )",
    ],
    additionalFeature: [
      "Oil Resistant F Class Polyurethane Varnished PU Fiberglass Braided Sleevings is braided with fiberglass and then coated by PU resin processed in high temperature.",
    ],
    additionalInnerSheath: [],
  },
  {
    id: "srbp_tubes",
    title: "SRBP Tubes",
    images: [srbp_tubes, srbp_tubes1],
    description: {
      brand: "",
      minimum_Order_quantity: "1000 Piece",
      color: "Brown",
      material: "Synthetic Resin Bonded Paper",
      length: "3 meter",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "2 mm to 6 mm",
      size: "3 inch",
      heat_resistance: "",
      image: [],
    },
    features: ["Lightweight", "High strength", "Flawless finish"],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "cotton_insulation_tapes",
    title: "Cotton Insulation Tapes",
    images: [cotton_insulation_tapes, cotton_insulation_tapes1],
    description: {
      brand: "",
      minimum_Order_quantity: "",
      color: "White",
      material: "Pure cotton fiber tape 100% cotton tape",
      length: "1-20 mtr",
      conductor_type: "",
      packaging_type: "Roll",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.2-0.4mm",
      size: "",
      heat_resistance: "",
      image: [],
    },
    features: [],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "fiberglass_tapes",
    title: "Fiberglass Tapes",
    images: [fiberglass_tapes, fiberglass_tapes1],
    description: {
      brand: "",
      minimum_Order_quantity: "",
      color: "White",
      material: "",
      length: "1-20 mtr",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.2-0.4mm",
      size: "0-20 mm",
      heat_resistance: "",
      image: [],
    },
    features: [
      "Water Proof, Heat Resistant",
      "Fiberglass insulation tape is made of nonalkali glassfiber yarn.",
      "It is woven by twisted strands of fiberglass at right angles to one another, having the highest glass-resin ratio and mechnical strength of all fiberglass insulaiton materials.",
    ],
    innerSheath: [],
    additionalFeature: [
      "Banding and reinforcing insulaiton materials for motors, transformers and cables",
    ],
    additionalInnerSheath: [],
  },
  {
    id: "polymide_tape",
    title: "Polymide / Kapton Tape",
    images: [polymide_tape, polymide_tape1],
    description: {
      brand: "",
      minimum_Order_quantity: "",
      color: "Brown",
      material: "Polyimide Tape High Temperature",
      length: "33 m",
      conductor_type: "",
      packaging_type: "Single Sided",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "",
      size: "1/2 - 3 inch",
      heat_resistance: "",
      image: [],
    },
    features: [
      "Surface assembly, for the protection of golden finger in wave soldering and re-flow soldering",
      "For the adhesion and class H insulation of electronic switch, motor, transformer, relay, wires, coil, capacitor and lithium battery etc.",
    ],
    innerSheath: [],
    additionalFeature: [
      "Product shelf life is 2 years from date of manufacture when stored at room temperature conditions (72 Degree F [22 Degree C] and 50% RH) in the products original packaging.",
    ],
    additionalInnerSheath: [],
  },
  {
    id: "pgmp_tapes",
    title: "PGMP Tapes",
    images: [pgmp_tapes, pgmp_tapes1],
    description: {
      brand: "",
      minimum_Order_quantity: "",
      color: "White, Bottel Green",
      material: "PGMP, POLYESTER MICA, POLYIMIDE MICA",
      length: "20-50 mm",
      conductor_type: "",
      packaging_type: "ROLLS",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.02 mm",
      size: "20-40 mm",
      heat_resistance: "",
      image: [],
    },
    features: [
      "Suitable for rotor bars and overhangs insulation of HT coils",
      "This specification laysdown requirements of Polyester Glass Mica Paper Polyester Tape.",
      "The materials shall be made from muscovite micapaper 100 gsm with Glass cloth backing",
      "1.7 mils thickness and reinforced both side with Polyester film 0.012 mm thickness",
      "With Isophthalic alkyd binder for the continuous operation at 155oC",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "dupont_nomex_laminated_paper",
    title: "DuPont Nomex Laminated Paper - F Class Paper - Eurotherm NPN",
    images: [dupont_nomex_laminated_paper, dupont_nomex_laminated_paper1],
    description: {
      brand: "",
      minimum_Order_quantity: "",
      color: "White, Bottel Green",
      material: "Polyester Film",
      length: "	2 m",
      conductor_type: "",
      packaging_type: "Insulation Paper",
      surface_treatment: "",
      temperature_range: "155 degree C",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "2.1 mm",
      size: "910 Millimeter (mm)",
      heat_resistance: "",
      image: [],
    },
    features: [
      "High chemical and solvent resistance.",
      "Protects the influence of hydrolysis.",
      "Suitable for automatic insertion.",
      "Superior Varnish absorption & Flexibility for Uncalendered Composites Higher Thermal Endurancesulation made from polyester film and Nomex.",
    ],
    innerSheath: [
      "Used in motors & generators As slot insulation / Closure, phase insulation/overhang, interphase insulation. In Dry Type Transformers As layer insulation for coils. For choke coils, conductor & shaped components.",
    ],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "laminated_fleece_dmd",
    title: "Laminated Fleece / DMD - Chinese Laminated Fleece",
    images: [laminated_fleece_dmd, laminated_fleece_dmd1],
    description: {
      brand: "",
      minimum_Order_quantity: "50 Kilogram",
      color: "White, Bottel Green",
      material: "PET film, covered on both sides with fleece",
      length: "	2 m",
      conductor_type: "",
      packaging_type: "	Insulation",
      surface_treatment: "",
      temperature_range: "155 Celsius (oC)",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "+-0.02 mm - (Nominal Thickness)	0.15 mm ",
      size: "910 Millimeter (mm)",
      heat_resistance: "",
      image: [laminated_fleece_dmd_data],
    },
    features: [
      "Excellent heat resistance, dielectric property and mechanical strength, good cutting and overhead resistance, high stiffness good compatibility with insulation varnish.",
      "DMD Paper is mainly used as a slot liner, slot closure and phase insulation in the production of low voltage motors. Besides this NMN is used as interlayer insulation in transformers and other electrical machines and appliances.",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "saturated_fleece_paper",
    title: "Saturated Fleece Paper",
    images: [saturated_fleece_paper, saturated_fleece_paper_1],
    description: {
      brand: "",
      minimum_Order_quantity: "100 Kilogram",
      color: "",
      material: "	Polyester",
      length: "",
      conductor_type: "",
      packaging_type: "	Roll",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "Insulation Class F",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "",
      size: "",
      heat_resistance: "",
      image: [],
    },
    features: [
      "High electrical & mechanical strength",
      "Protects polyester film at high temperatures",
      "Resistance to another impregnation agent",
      "Easy insertion",
      "High smoothness",
      "Remarkable Varnish Absorption",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "dupont_nomex",
    title: "DuPont Brand Pure Nomex - Insulation Paper",
    images: [dupont_nomex, dupont_nomex1],
    description: {
      brand: "Nomex",
      minimum_Order_quantity: "100 Kilogram",
      color: "White",
      material: "Paper",
      length: "",
      conductor_type: "",
      packaging_type: "	Roll",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "0.25-2 mm",
      size: "",
      heat_resistance: "",
      image: [],
    },
    features: [
      "Mechanical toughness and (in the grade) flexibility",
      "Inherent Di-Electric Strength",
      "Unaffected by most solvents, acids",
      "Compatible with varnishes, adhesives & transformer fluids",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "unsaturated_fleece_paper",
    title: "Unsaturated Fleece Paper",
    images: [unsaturated_fleece_paper, dupont_nomex],
    description: {
      brand: "Nomex",
      minimum_Order_quantity: "100 Kilogram",
      color: "White",
      material: "Flexible Insulating Material Class b (F)",
      length: "",
      conductor_type: "",
      packaging_type: "	Roll",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "20mm",
      size: "1000 mm",
      heat_resistance: "",
      image: [],
    },
    features: [
      "Excellent electrical and mechanical properties",
      "Excellent impregnating capacity",
      "Suitable for automatic insertion",
      "Protection against the influence of hydrolysis and chemical actions",
      "Saves on copper and steel for rated power",
      "High Flexibility",
    ],
    innerSheath: [],
    additionalFeature: [
      "Used in Motors & Generators: As slot insulation / Closure, phase insulation/overhang, interphase insulation. In Dry Type Transformers: As layer insulation for coils. For choke coils, conductor & shaped components",
    ],
    additionalInnerSheath: [],
  },
  {
    id: "polyester_film_electrical_insulation",
    title: "Polyester Film - Electrical Insulation",
    images: [
      polyester_film_electrical_insulation,
      polyester_film_electrical_insulation1,
    ],
    description: {
      brand: "Nomex",
      minimum_Order_quantity: "100 Kilogram",
      color: "Milky / Transparent",
      material: "Polyester",
      length: "",
      conductor_type: "",
      packaging_type: "	Roll",
      surface_treatment: "",
      temperature_range: "105 Deg. Cel",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "20mm",
      size: "1000 mm",
      heat_resistance: "",
      image: [polyester_film_electrical_insulation_data],
    },
    features: [
      "Motors / Transformers / Packaging / Electrical Insulation",
      "Polyester insulation film roll",
      "Milky white colour",
      "High heat resistance",
      "100 cm length x 30 cm breadth roll",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "pure_aramid_paper_(ul_approved)",
    title: "Pure Aramid Paper (UL approved)",
    images: [pure_aramid_paper_ul_approved, pure_aramid_paper_ul_approved1],
    description: {
      brand: "Aramid",
      minimum_Order_quantity: "10 KG",
      color: "White",
      material: "Aramid paper",
      length: "",
      conductor_type: "",
      packaging_type: "	Roll",
      surface_treatment: "",
      temperature_range: "210 Degree C",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "2mil - 50mil",
      size: "26+-3 G/m2",
      heat_resistance: "",
      image: [pure_aramid_paper_ul_approved_data],
    },
    features: [
      "Longer service life",
      "Easy installation",
      "Rated Temperature 200 Degreec",
      "Rated Temperature 200 Degreec",
      "Insulation Material PTFE",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "laminated_aramid_paper_apa",
    title: "Laminated Aramid Paper APA",
    images: [laminated_aramid_paper_apa, laminated_aramid_paper_apa1],
    description: {
      brand: "Aramid",
      minimum_Order_quantity: "50 KG",
      color: "White",
      material: "Aramid Chopped Fiber",
      length: "",
      conductor_type: "",
      packaging_type: "	Roll",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "",
      size: "",
      heat_resistance: "",
      image: [laminated_aramid_paper_apa_data],
    },
    features: [
      "Temperature classification: Class F",
      "thickness:0.15-0.50mm",
      "Width:900mm",
      "Features: For H class motor and generator insulation between the groove, can also be used betwwen layers of dry -type transformers, the interval insulation.",
      "color: Nomex paper with a yellow line or orange line",
      "It is widely used as the slot insulation and insulation between phases for Y-series motors.",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "dr_beck_insulating_varnish",
    title: "Dr Beck Insulating Varnish",
    images: [dr_beck_insulating_varnish, dr_beck_insulating_varnish1],
    description: {
      brand: "Dr Beck",
      minimum_Order_quantity: "1 Box",
      color: "",
      material: "Insulating Varnish",
      length: "",
      conductor_type: "",
      packaging_type: "	",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "",
      size: "",
      heat_resistance: "",
      image: [dr_beck_insulating_varnish_data],
    },
    features: [
      "Increased mechanical bonding to the winding wire",
      "Improved dielectric properties",
      "Improved thermal conductivity",
      "Protection to the winding against moisture & chemically corrosive Environment",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "dr_beck_elmotherm_f_50_varnishes",
    title: "Dr. Beck Elmotherm F 50 Varnishes",
    images: [dr_beck_elmotherm_f_50_varnishes, dr_beck_insulating_varnish],
    description: {
      brand: "Dr Beck",
      minimum_Order_quantity: "1 Box",
      color: "",
      material: "Elmotherm Varnish",
      length: "",
      conductor_type: "",
      packaging_type: "1-2.5 Ltr",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "",
      size: "",
      heat_resistance: "",
      image: [dr_beck_elmotherm_f_50_varnishes_data],
    },
    features: [
      "Increased mechanical bonding to the winding wire",
      "Improved dielectric properties",
      "Improved thermal conductivity",
      "Protection to the winding against moisture & chemically corrosive Environment",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "becktol_red_grey_varnish",
    title: "Becktol Red / Grey Varnish",
    images: [becktol_red_grey_varnish, becktol_red_grey_varnish1],
    description: {
      brand: "Elantas",
      minimum_Order_quantity: "1 Box",
      color: "Red / Grey",
      material: "Becktol  Varnish",
      length: "",
      conductor_type: "",
      packaging_type: "1-5 Ltr",
      surface_treatment: "",
      temperature_range: "",
      voltage: "",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "",
      thickness_variation: "",
      size: "",
      heat_resistance: "",
      image: [becktol_red_grey_varnish1_data],
    },
    features: [
      "Increased mechanical bonding to the winding wire",
      "Improved dielectric properties",
      "Improved thermal conductivity",
      "Protection to the winding against moisture & chemically corrosive Environment",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "ptfe_wire",
    title: "PTFE Wire",
    images: [ptfe_wire, ptfe_wire1],
    description: {
      brand: "PTFE",
      minimum_Order_quantity: "100 Meter",
      color: "red,yellow,blue,white,black,green,brown,orange,purple,green,etc",
      material: "silver or Nickel copper",
      length: "",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "",
      voltage: "1100",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "PTFE teflon",
      thickness_variation: "",
      size: "32AWG~10AWG",
      heat_resistance: "",
      image: [],
    },
    features: [
      "Widely used in lighting, household appliances, , lamps.",
      "electric appliances, instrumentation, motor wiring and electronics.",
      "gas appliances and other high-temperature environment.",
    ],
    innerSheath: [],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
  {
    id: "teflon_cable",
    title: "Teflon Cable",
    images: [teflon_cable, teflon_cable1],
    description: {
      brand: "PTFE",
      minimum_Order_quantity: "100 Meter",
      color: "red,yellow,blue,white,black,green,brown,orange,purple,green,etc",
      material: "PTFE",
      length: "",
      conductor_type: "",
      packaging_type: "",
      surface_treatment: "",
      temperature_range: "200 Degree",
      voltage: "1100",
      power: "",
      diameter: "",
      tensile_strength: "",
      insulation_material: "",
      reference_standards: "",
      available_sizes: "",
      insulation_class: "PTFE teflon",
      thickness_variation: "",
      size: "",
      heat_resistance: "",
      image: [teflon_cable_data],
    },
    features: [
      "Fire proof",
      "Reliable",
      "Resistant to chemicals",
      "Durable",
      "Functional efficiency is optimum",
      "High performance",
    ],
    innerSheath: [
      "ISO 9001, ISI , CE( EUROPEAN CERTIFIED ) , ANAB , CRISIL , CERTIFIED COMPANY.",
      "Winner of All India Award for Export Excellence for 2006-07, 2007-08, 2008-09, 2009-10, 2010-11",
      "Readily available from stock.",
      "A full range of High temperature cables",
      "The Products You Want, When You Want Them",
      "Quality You Can Rely On Every Time",
      "World leader in the supply of high quality wires & cables",
      "High level of expertise, in-depth product knowledge",
      "Competitive prices and realistic delivery schedules",
    ],
    additionalFeature: [],
    additionalInnerSheath: [],
  },
];
